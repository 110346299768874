<template>
  <div v-if="showBorder" class="phoneWrapper">
    <router-view/>
  </div>
  <router-view v-else/>
</template>

<script>
export default {
  name: "PhoneEmulator",
  data () {
    return {
      windowHeight: 0,
      windowWidth: 0,
      showBorder: true,
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
      this.showBorder = !(this.windowWidth < 375 || this.windowHeight < 667);
    })
  },
}
</script>

<style scoped>
.phoneWrapper {
  background: white;
  width: 375px;
  height: 667px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
