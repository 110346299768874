<template>
  <div id="app">
    <phone-emulator/>
  </div>
</template>

<script>

import PhoneEmulator from "./components/PhoneEmulator";


export default {
  name: 'App',
  components: {
    PhoneEmulator
  },
  computed: {
    currentUser () {
      return this.$store.state.user
    }
  },
  watch: {
    currentUser() {
      this.redirectToRegisterIfNeeded()
    }
  },
  mounted() {
    this.redirectToRegisterIfNeeded()
  },
  methods: {
    redirectToRegisterIfNeeded () {
      if (this.currentUser.tel === undefined) {
        this.$router.push('register').catch(()=>{})
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  background: #a8aaaa;
  width: 100vw;
  height: 100vh;
}

.view {
  height: 100%;
  width: 100%;
  background: white;
}

.view .content {
  overflow-y: scroll;
  max-height: calc(100% - 72px);
}

.view .content > .container {
  width: 85%;
  border-radius: 8px;
  padding: 8px 15px;
}

.view .wrapper {
  margin: 0 auto;
  width: 80%;
}

.btn-green {
  padding: 8px;
  width: 100%;
  background: #21BD64;
  color: white;
  border-radius: 8px;
}

.btn-red {
  padding: 8px;
  width: 100%;
  background: #FF5E60;
  color: white;
  border-radius: 8px;
}

.btn-grey {
  padding: 8px;
  width: 100%;
  background: #E3E3E3;
  color: black;
  border-radius: 8px;
}

.green-bg {
  background-color: #21BD64;
}

.red-bg {
  background-color: #FF5E60;
}

.grey-bg {
  background-color: #E3E3E3;
}
</style>

<style lang="scss">
@mixin scrollbars($size, $radius, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
// For Google Chrome
  & *::-webkit-scrollbar {
  width: $size;
  height: $size;
}

& *::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $radius;
  }

& *::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: $radius;
  }

// For Internet Explorer and Firefox
  & * {
     scrollbar-face-color: $foreground-color !important;
     scrollbar-track-color: $background-color !important;
     scrollbar-width: thin !important;
     scrollbar-color: $foreground-color $background-color !important;
   }
}

html, body {
  @include scrollbars(5px, 5px, lightgray);
}

</style>
