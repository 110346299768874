import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/index',
        },
        {
            path: '/index',
            name: 'Index',
            component: () => import('./views/Index'),
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('./views/Register'),
        },
        {
            path: '/tests',
            name: 'Tests',
            component: () => import('./views/Tests'),
        },
        {
            path: '/checkins',
            name: 'CheckIns',
            component: () => import('./views/CheckIns'),
        },
        {
            path: '/settings',
            name: 'Settings',
            component: () => import('./views/Settings'),
        },
        {
            path: '/scan',
            name: 'Scan',
            component: () => import('./views/Scan'),
            props: true,
        },
        {
            path: '/tests/new',
            name: 'TestsNew',
            component: () => import('./views/TestsNew'),
        },
        {
            path: '/tests/new/manuel',
            name: 'TestsNewManuel',
            component: () => import('./views/TestsNewManual'),
        },
        {
            path: '/qr',
            name: 'QR',
            component: () => import('./views/OwnQRCode'),
        },
        {
            path: '/qr/new',
            name: 'QRNew',
            component: () => import('./views/NewOwnQrCode'),
        },
        {
            path: '/qr/created',
            name: 'QRNewCreated',
            component: () => import('./views/NewQRCodeCreated'),
        },
    ],
})
