import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        positiveTest: false,
        checkedIn: false,
        risk: false,
        checkIns: [
            // {
            //     id: this.getCheckIns.length + 1,
            //     name: "Location" + (this.getCheckIns.length + 1),
            //     date: (new Date()),
            //     endDate:  (new Date()),
            //     defaultStayTime: this.randomIntFromInterval(15, 20),
            // }
        ],
        tests: [
            // {
            //     "id": 0,
            //     "date": "2021-06-20T15:35:43.716Z",
            //     "result": "Negativ",
            //     "testNumber": 0.8373085555853741,
            //     "testCenter": "TestCenter"
            // }
        ],
        qrCodes: [
            // {
            //     "id": 0,
            //     "name": "Name",
            //     "maxPerson": "5",
            //     "duration": "3",
            //     "address": "Adresse",
            //     "date": "2021-06-20T16:35:41.521Z"
            // }
        ],
        user: {}
    },
    mutations: {
        setTestPositive(state) {
            state.positiveTest = true
        },
        setTestNegative(state) {
            state.positiveTest = false
        },
        toggleTest(state) {
            state.positiveTest = !state.positiveTest
        },
        toggleRisk(state) {
            state.risk = !state.risk
        },
        checkOut(state) {
            state.checkedIn = false
            state.checkIns[state.checkIns.length -1].endDate = (new Date())
        },
        addCheckIn(state, checkin) {
            state.checkedIn = true
            state.checkIns.push(checkin)
        },
        addTest(state, test) {
            state.tests.push(test)
        },
        addQrCode(state, qr) {
            state.qrCodes.push(qr)
        }
    },
    getters: {
        getRisk(state) {
            return state.risk
        },
        getCheckedIn(state) {
            return state.checkedIn
        },
        getCheckIns(state) {
            return state.checkIns
        },
        getLatestCheckIn(state) {
            return state.checkIns[state.checkIns.length -1]
        },
        getTests(state) {
            return state.tests
        },
        getQRCodes(state) {
            return state.qrCodes
        },
        getUser(state) {
            return state.user
        }
    }
})
